import React from 'react';
import {  Box } from '@mui/material';
import Hero from "../components/hero/hero";
import MovieRowHomePage from "../components/movieRow/movieRowHomePage";

const trendingMovies = [
    {
        id: 1,
        title: "Inception",
        image: "https://images.unsplash.com/photo-1626814026160-2237a95fc5a0?auto=format&fit=crop&q=80"
    },
    {
        id: 2,
        title: "The Matrix",
        image: "https://images.unsplash.com/photo-1536440136628-849c177e76a1?auto=format&fit=crop&q=80"
    },
    {
        id: 3,
        title: "Interstellar",
        image: "https://images.unsplash.com/photo-1534447677768-be436bb09401?auto=format&fit=crop&q=80"
    }
];

const popularSeries = [
    {
        id: 4,
        title: "Breaking Bad",
        image: "https://images.unsplash.com/photo-1594909122845-11baa439b7bf?auto=format&fit=crop&q=80"
    },
    {
        id: 5,
        title: "Stranger Things",
        image: "https://images.unsplash.com/photo-1574375927938-d5a98e8ffe85?auto=format&fit=crop&q=80"
    },
    {
        id: 6,
        title: "The Crown",
        image: "https://images.unsplash.com/photo-1578022761797-b8636ac1773c?auto=format&fit=crop&q=80"
    }
];

function Dashboard() {
    return (
            <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
                <Hero />
                <Box sx={{ mt: 8 }}>
                    <MovieRowHomePage title="Trending Now" movies={trendingMovies} />
                    <MovieRowHomePage title="Popular TV Series" movies={popularSeries} />
                </Box>
            </Box>
    );
}

export default Dashboard;
