import React from 'react';
import { Play, Users } from 'lucide-react';
import { Box, Typography, Button, Container, Stack } from '@mui/material';
import {useNavigate} from "react-router-dom";

export default function Hero() {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                position: 'relative',
                height: { xs: '60vh', sm: '70vh', md: '80vh' },
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        background: 'linear-gradient(to top, #141414 0%, rgba(20,20,20,0.5) 50%, transparent 100%)',
                    },
                }}
            >
                <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1626814026160-2237a95fc5a0?auto=format&fit=crop&q=80"
                    alt="Hero Background"
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>

            <Container
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Box maxWidth={{ xs: 'xs', sm: 'md', md: 'lg' }}>
                    <Typography
                        variant="h2"
                        component="h1"
                        fontWeight="bold"
                        sx={{
                            fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
                            mb: { xs: 1, sm: 2 },
                        }}
                    >
                        Watch Together
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
                            mb: { xs: 2, sm: 3, md: 4 },
                            color: 'text.secondary',
                        }}
                    >
                        Create a party, invite your friends, and enjoy your favorite movies and shows together.
                        Sync playback and chat in real-time!
                    </Typography>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <Button
                            onClick={() => navigate('/createParty')}
                            variant="contained"
                            size="large"
                            startIcon={<Users />}
                        >
                            Create Party
                        </Button>
                        <Button
                            onClick={() => navigate('/home')}
                            variant="outlined"
                            size="large"
                            startIcon={<Play />}
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                backdropFilter: 'blur(10px)',
                                backgroundColor: 'rgba(255,255,255,0)',
                            }}
                        >
                            Show Party
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
}
