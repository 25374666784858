import { MovieResultVote } from "../../models/MovieResponse";
import { Box, Chip, Container, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import PollIcon from '@mui/icons-material/Poll';
import * as _ from "lodash";
import { Category } from "../../models/Category";
import { WatchProvider } from "../../models/WatchProviders";

export const MovieDetail: React.FC<{
    movie: MovieResultVote;
    watchProvider: WatchProvider[];
    category: Category[];
    watchRegion: string;
}> = ({ movie, watchProvider, category, watchRegion }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100vh',
                background: 'linear-gradient(to bottom, #000000, #141414)',
                position: 'relative',
            }}
        >
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    height: { xs: '70vh', md: '80vh' },
                    width: '100%',
                    overflow: 'hidden',
                }}
            >
                {/* Background Image with Gradient Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(https://image.tmdb.org/t/p/original${movie.backdrop_path || movie.poster_path})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'linear-gradient(0deg, #000000 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.4) 100%)',
                        },
                    }}
                />

                {/* Content Overlay */}
                <Container
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'center', md: 'flex-end' },
                        justifyContent: { xs: 'flex-end', md: 'flex-start' },
                        pb: 4,
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    {/* Poster */}
                    {!isMobile && <Box
                        component="img"
                        src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                        alt={movie.title}
                        sx={{
                            width: { xs: '200px', md: '300px' },
                            height: 'auto',
                            borderRadius: 2,
                            boxShadow: '0 8px 32px rgba(0,0,0,0.5)',
                            mb: { xs: 3, md: 0 },
                            mr: { md: 4 },
                        }}
                    />}

                    {/* Movie Info */}
                    <Box sx={{ flex: 1, color: 'white' }}>
                        <Typography
                            variant="h3"
                            component="h1"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                fontSize: { xs: '2rem', md: '3rem' },
                            }}
                        >
                            {movie.title}
                        </Typography>

                        {/* Metadata Row */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 3,
                                mb: 3,
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="body1" color="grey.400">
                                {movie.release_date.slice(0, 4)}
                            </Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <StarIcon sx={{ color: '#E50914' }} />
                                <Typography>
                                    {movie.vote_average.toPrecision(2)}/10
                                </Typography>
                                <Typography color="grey.400" sx={{ ml: 1 }}>
                                    ({movie.vote_count})
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PollIcon sx={{ color: '#E50914' }} />
                                <Typography>{movie.resultVote}</Typography>
                            </Box>
                        </Box>

                        {/* Categories */}
                        {movie.genre_ids?.length > 0 && (
                            <Box sx={{ mb: 3 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 1,
                                        color: '#E50914',
                                        fontWeight: 500,
                                    }}
                                >
                                    Categories
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                    }}
                                >
                                    {_.uniq(movie.genre_ids)?.map((genreId) => {
                                        const cat = category.find((c) => parseInt(c.id) === genreId);
                                        if (cat) {
                                            return (
                                                <Chip
                                                    key={cat.id}
                                                    label={cat.name}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        borderColor: 'rgba(255,255,255,0.3)',
                                                        color: 'white',
                                                        '&:hover': {
                                                            borderColor: '#E50914',
                                                        },
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </Box>
                            </Box>
                        )}

                        {/* Streaming Platforms */}
                        {(movie.watchProviders?.results[watchRegion]?.flatrate?.length ?? 0) > 0 && (
                            <Box sx={{ mb: 3 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 1,
                                        color: '#E50914',
                                        fontWeight: 500,
                                    }}
                                >
                                    Available on
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                    }}
                                >
                                    {_.uniq(movie.watchProviders?.results[watchRegion].flatrate)?.map((provider) => (
                                        <Chip
                                            key={provider.provider_id}
                                            label={provider.provider_name}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                borderColor: 'rgba(255,255,255,0.3)',
                                                color: 'white',
                                                '&:hover': {
                                                    borderColor: '#E50914',
                                                },
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}

                        {/* Overview */}
                        {movie.overview && (
                            <Box>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 1,
                                        color: '#E50914',
                                        fontWeight: 500,
                                    }}
                                >
                                    Summary
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="grey.300"
                                    sx={{
                                        maxWidth: '800px',
                                        lineHeight: 1.6,
                                    }}
                                >
                                    {movie.overview.length > 400
                                        ? `${movie.overview.slice(0, 400)}...`
                                        : movie.overview}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
