import {
    AppBar,
    Box, Button,
    Chip,
    CircularProgress, Container,
    Dialog,
    Grid,
    IconButton,
    ImageListItem,
    Slide,
    Snackbar,
    SnackbarContent,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PartyPublicInfo} from "../../models/PartyPublicInfo";
import {Colors} from "../../utils/theme";
import * as _ from "lodash";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import filmatchBE from "../../service/FilmatchBE";
import {useSession, useSessionAction} from "../../hooks/useSession";
import DoneIcon from "@mui/icons-material/Done";
import {TransitionProps} from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import {MovieDetail} from "../movie/movieDetail";
import {MovieResultVote} from "../../models/MovieResponse";
import {WatchProvider} from "../../models/WatchProviders";
import {Category} from "../../models/Category";
import {notifyError} from "../../utils/notification";
import {useNotificationActions} from "../../hooks/useNotification";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {UserPublicInfo} from "../../models/User";
import {Film, MonitorPlay, PlayCircle, Share2, Trophy, Users} from "lucide-react";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PartyCard: React.FC<{ party: PartyPublicInfo }> = ({party}) => {
    const navigate = useNavigate();
    const session = useSession();
    const {setSession} = useSessionAction();
    const {addNotification} = useNotificationActions();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [watchProvidersList, setWatchProvidersList] = React.useState<string[]>([])
    const [selectedMovie, setSelectedMovie] = React.useState<MovieResultVote | undefined>(undefined)
    const [openDialog, setOpen] = React.useState(false)
    const [categoryList, setCategoryList] = React.useState<string[]>([])
    const [watchProviderFullList, setWatchProviderFullList] = React.useState<WatchProvider[]>([]);
    const [categoryFullList, setCategoryFullList] = React.useState<Category[]>([])
    const [partyResultVote, setPartyResultVote] = useState<MovieResultVote[]>([])
    const [loadingMovies, setLoadingMovies] = useState<boolean>(true)

    const handleClickOpenDialog = (movie: MovieResultVote) => {
        setSelectedMovie(movie);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedMovie(undefined);
        setOpen(false);
    };

    useEffect(() => {
        party?.otherInformation?.with_watch_providers?.split('|')?.forEach((watchProvider) => {
            setWatchProvidersList((watchProvidersList) => {
                return [...watchProvidersList, watchProvider]
            });
        });
        party?.otherInformation?.with_genres?.split('|')?.forEach((category) => {
            if (category.length > 0) {
                setCategoryList((categoryList) => {
                    return [...categoryList, category]
                });
            }
        });
        (async () => {
            try {
                const categories = await filmatchBE.getCategories();
                setCategoryFullList(categories);
            } catch (e: any) {
            }
        })();
        (async () => {
            try {
                const watchProviders = await filmatchBE.getWatchProviders(party.otherInformation.watch_region ?? 'US');
                setWatchProviderFullList(watchProviders);
            } catch (e: any) {
            }
        })();
        (async () => {
            try {
                if (party.codeParty) {
                    const partyResultVoteResponse = await filmatchBE.getResultParty(party.codeParty);
                    setPartyResultVote(partyResultVoteResponse);
                    setLoadingMovies(false);
                }

            } catch (e: any) {
                setLoadingMovies(false);
                if (e.res.status === 401) {
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigate('/signin')
                } else {
                    notifyError('Errore nel caricamento dei risultati', addNotification)
                }
            }
        })();
    }, []);

    const joinParty = async () => {
        if (session?.user && party.codeParty) {
            setLoadingButton(true);
            await filmatchBE.joinParty(session?.user, party.codeParty);
            setLoadingButton(false);
            navigate('/party/' + party.codeParty + '/0');
        } else {
            navigate('/signin');
        }
    }

    const copyCodeParty = () => {
        setOpenSnackbar(true);
        navigator.clipboard.writeText(`https://filmatch.app/join/party/${party.codeParty}`);
    }

    const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default' }}>
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    height: { xs: '40vh', md: '60vh' },
                    backgroundImage: 'url(https://images.unsplash.com/photo-1536440136628-849c177e76a1?auto=format&fit=crop&w=2000)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 100%)',
                    },
                }}
            >
                <Container
                    sx={{
                        height: '100%',
                        position: 'relative',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        pb: 6,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                        <Users size={24} color="#E50914" />
                        <Typography variant="h6" sx={{ color: 'grey.400' }}>
                            {(party.members as UserPublicInfo[]).length} Members
                        </Typography>
                    </Box>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            color: 'white',
                            fontSize: { xs: '2rem', md: '3.5rem' },
                            mb: 2,
                        }}
                    >
                        {party.partyName}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {loadingButton ? <CircularProgress size={15} sx={{color: Colors.White.main}}/> :
                            <Button
                                variant="contained"
                                startIcon={<PlayCircle />}
                                onClick={joinParty}
                                sx={{
                                    backgroundColor: '#E50914',
                                    px: 4,
                                    py: 1.5,
                                    '&:hover': { backgroundColor: '#b2070f' },
                                }}
                            >
                                Start Voting
                            </Button>
                        }
                        <IconButton
                            onClick={copyCodeParty}
                            sx={{
                                border: '2px solid rgba(255,255,255,0.2)',
                                p: 1.5,
                                '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' },
                            }}
                        >
                            <Share2 />
                        </IconButton>
                    </Box>
                </Container>
            </Box>

            {/* Content Section */}
            <Container sx={{ mt: -4, position: 'relative', zIndex: 2 }}>
                {/* Info Cards */}
                <Grid container spacing={3} sx={{ mb: 6 }}>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: 'background.paper',
                                p: 3,
                                borderLeft: '4px solid #E50914',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                                <Film size={24} color="#E50914" />
                                <Typography variant="h6" sx={{ color: 'white' }}>Categories</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {_.uniq(categoryList).map((category) => {
                                    return (
                                        <Typography
                                            key={category}
                                            sx={{
                                                color: 'grey.400',
                                                fontSize: '0.9rem',
                                                '&:not(:last-child)::after': {
                                                    content: '"•"',
                                                    marginLeft: 1,
                                                    marginRight: 1,
                                                    color: '#E50914',
                                                },
                                            }}
                                        >
                                            {category}
                                        </Typography>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundColor: 'background.paper',
                                p: 3,
                                borderLeft: '4px solid #E50914',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                                <MonitorPlay size={24} color="#E50914" />
                                <Typography variant="h6" sx={{ color: 'white' }}>Platforms</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {_.uniq(watchProvidersList).map((watchProvider) => {
                                    return (
                                        <Typography
                                            key={watchProvider}
                                            sx={{
                                                color: 'grey.400',
                                                fontSize: '0.9rem',
                                                '&:not(:last-child)::after': {
                                                    content: '"•"',
                                                    marginLeft: 1,
                                                    marginRight: 1,
                                                    color: '#E50914',
                                                },
                                            }}
                                        >
                                            {watchProvider}
                                        </Typography>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Movies Section */}
                <Box sx={{ mb: 8 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
                        <Trophy size={24} color="#E50914" />
                        <Typography variant="h5" sx={{ color: 'white' }}>
                            Selected Movies
                        </Typography>
                        <Chip sx={{position: 'relative'}} color={'primary'}
                              label={`${partyResultVote?.length}`}/>
                    </Box>
                    <Grid container spacing={3}>
                        {!loadingMovies ?
                            <div style={{display: 'flex', overflowX: 'auto', flexDirection: 'row', width: 'auto', height: 230}}>
                                {partyResultVote.map((item, index) => (
                                    <div onClick={() => handleClickOpenDialog(item)}>
                                        <ImageListItem sx={{ml: 1, mr: 1}}
                                                       key={`https://image.tmdb.org/t/p/w200${item.poster_path}`}>
                                            <Chip sx={{mb: -5, ml: -1, position: 'relative'}} color={'primary'}
                                                  label={`${index + 1}`}/>
                                            <img style={{height: 200, width: 150, borderRadius: 18}}
                                                 src={`https://image.tmdb.org/t/p/w200${item.poster_path}`} alt={item.title}/>
                                        </ImageListItem>
                                    </div>
                                ))}
                            </div> : <CircularProgress/>
                        }
                    </Grid>
                </Box>
            </Container>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={closeSnackBar}
            >
                <SnackbarContent style={{backgroundColor: Colors.Black.main, color: Colors.White.main}}
                                 message={<span style={{display: 'flex', alignItems: 'center'}}>
              <DoneIcon style={{marginRight: '8px'}}/>
              <Typography>Party copied to clipboard</Typography>
            </span>
                                 }/>
            </Snackbar>
            <Dialog
                fullScreen
                open={openDialog}
                onClose={handleCloseDialog}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: Colors.Black.main,
                    },
                }}
            >
                <Box sx={{backgroundColor: Colors.Black.main}} height={900}>
                    <AppBar sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'flex-end',
                        bgcolor: Colors.Black.dark
                    }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseDialog}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box mt={2}>
                        {selectedMovie ? <MovieDetail movie={selectedMovie} watchProvider={watchProviderFullList}
                                                      category={categoryFullList}
                                                      watchRegion={party.otherInformation.watch_region ?? 'US'}/> : null}
                    </Box>
                </Box>

            </Dialog>
        </Box>
    )
}
