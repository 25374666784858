import React from 'react';
import './App.css';
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {SessionProvider} from "./components/session/SessionContext";
import {AccessPage} from "./pages/access.page";
import {Box, createTheme, CssBaseline, ThemeProvider, Typography, useMediaQuery} from "@mui/material";
import {HomePage} from "./pages/home.page";
import {CreatePartyPage} from "./pages/createParty.page";
import ErrorBoundary from "./components/errorBoundary/errorBoundary";
import {NotificationProvider} from "./components/notification/notificationContext";
import {PartyPage} from "./pages/party.page";
import {PartyResultPage} from "./pages/partyResult.page";
import Notification from "./components/notification/Notification";
import {Navbar} from "./components/navbar/navbar";
import {SearchPartyPage} from "./pages/searchParty.page";
import {ProfilePage} from "./pages/profile.page";
import {darkTheme} from "./utils/theme";
import AppBarCustom from "./components/appbar/appBarCustom";
// import {DesktopSoonPage} from "./pages/desktopSoon.page";
import {JoinPartyPage} from "./pages/joinParty.page";
import Dashboard from "./pages/dashboard";

export const App: React.FC = () => {
    // const isMobileDevice = useMediaQuery('(max-width:600px)');
    return (
        <>
                <NotificationProvider>
                    <Router>
                        <ErrorBoundary>
                            <SessionProvider>
                                <ThemeProvider theme={darkTheme}>
                                    <CssBaseline/>
                                    <Routes>
                                        <Route path="/signin/:fallback" element={<AccessPage redirect={true}/>}/>
                                        <Route path="/signin" element={<AccessPage redirect={true}/>}/>
                                        <Route path="/login" element={<AccessPage redirect={true}/>}/>
                                        <Route path="/" element={<AccessPage redirect={true}/>}/>
                                        <Route path="/join/party/:partyId"
                                               element={<Box><AppBarCustom/><JoinPartyPage/></Box>}/>
                                        <Route path="/dashboard" element={<Box><AppBarCustom/><Dashboard/><Navbar/></Box>}/>
                                        <Route path="/home" element={<Box><AppBarCustom/><HomePage/><Navbar/></Box>}/>
                                        <Route path="/searchParty"
                                               element={<Box><AppBarCustom/><SearchPartyPage/><Navbar/></Box>}/>
                                        <Route path="/createParty"
                                               element={<Box><AppBarCustom/><CreatePartyPage/><Navbar/></Box>}/>
                                        <Route path="/party/:partyId/:sequence"
                                               element={<Box><PartyPage/><Navbar/></Box>}/>
                                        <Route path="/party/:partyId" element={<Box><PartyPage/><Navbar/></Box>}/>
                                        <Route path="/partyresult/:partyId"
                                               element={<Box><PartyResultPage/><Navbar/></Box>}/>
                                        <Route path="/profile"
                                               element={<Box><AppBarCustom/><ProfilePage/><Navbar/></Box>}/>
                                    </Routes>
                                    <Notification/>
                                </ThemeProvider>
                            </SessionProvider>
                        </ErrorBoundary>
                    </Router>
                </NotificationProvider>
        </>
  )};

export default App;
