import React from 'react';
import { Box, Typography } from '@mui/material';
import MovieCardHomePage from "../MovieCard/movieCardHomePage";

interface Movie {
    id: number;
    title: string;
    image: string;
}

interface MovieRowProps {
    title: string;
    movies: Movie[];
}

export default function MovieRowHomePage({ title, movies }: MovieRowProps) {
    return (
        <Box sx={{ px: { xs: 2, sm: 3, md: 4 }, mb: { xs: 3, md: 4 } }}>
            <Typography
                variant="h6"
                component="h2"
                sx={{
                    fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                    fontWeight: 600,
                    mb: { xs: 1.5, md: 2 },
                }}
            >
                {title}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 1, sm: 2 },
                    overflowX: 'auto',
                    pb: 2,
                    '&::-webkit-scrollbar': { display: 'none' },
                    scrollbarWidth: 'none',
                }}
            >
                {movies.map((movie) => (
                    <MovieCardHomePage key={movie.id} title={movie.title} image={movie.image} />
                ))}
            </Box>
        </Box>
    );
}
