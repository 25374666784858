import React from 'react';
import { Play } from 'lucide-react';
import { Box, Typography, Card, CardMedia, IconButton } from '@mui/material';

interface MovieCardProps {
    title: string;
    image: string;
}

export default function MovieCardHomePage({ title, image }: MovieCardProps) {
    return (
        <Card
            sx={{
                width: { xs: 140, sm: 160, md: 200 },
                bgcolor: 'transparent',
                boxShadow: 'none',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'scale(1.05)',
                    '& .MuiBox-root': {
                        opacity: 1,
                    },
                },
            }}
        >
            <Box sx={{ position: 'relative', aspectRatio: '16/9' }}>
                <CardMedia
                    component="img"
                    image={image}
                    alt={title}
                    sx={{ height: '100%', borderRadius: 1 }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        bgcolor: 'rgba(0,0,0,0.4)',
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IconButton color="inherit">
                        <Play />
                    </IconButton>
                </Box>
            </Box>
            <Typography
                variant="body2"
                sx={{
                    mt: 1,
                    color: 'text.secondary',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {title}
            </Typography>
        </Card>
    );
}
